import { useSelector } from "react-redux";
import { Derby } from "../types";

function WelcomeMessage() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);

  return (
    <div className="grid h-dvh place-items-center font-racing">
      <div>
        <div className="text-center text-7xl">Welcome to</div>
        <div className="text-center text-9xl">{derby.name}</div>
      </div>
    </div>
  );
}

export default WelcomeMessage;
