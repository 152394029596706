import { useDispatch, useSelector } from "react-redux";
import { Derby } from "../types";
import { addRacer } from "../store/derby";
import EditableRacer from "../components/EditableRacer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { exportData } from "../utils";
function Racers() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);
  const dispatch = useDispatch();

  const handleAddRacer = () => {
    dispatch(addRacer());
  };
  const handleExportRacers = () => {
    const data = derby.racers
      .map((r) => [r.name, r.number].join(","))
      .join("\n");
    exportData("open_derby_racers.csv", new Blob(["name,car\n", data]));
  };

  return (
    <div className="container mx-auto">
      <div className="flex flex-col">
        {derby.racers.map((_, i) => (
          <EditableRacer index={i} key={i} />
        ))}
        <div className="flex justify-center">
          <button
            className="mx-2 my-2 rounded-full bg-green-600 px-5 py-2 text-white shadow-md hover:bg-green-400"
            onClick={handleAddRacer}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Add Racer
          </button>
          <button
            className="mx-2 my-2 rounded-full bg-yellow-600 px-5 py-2 text-white shadow-md hover:bg-yellow-400"
            onClick={handleExportRacers}
          >
            <FontAwesomeIcon icon={faFileExport} /> Export Racers
          </button>
        </div>
      </div>
    </div>
  );
}

export default Racers;
