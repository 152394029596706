import { useDispatch, useSelector } from "react-redux";
import { Derby, SettingKey } from "../types";
import { setSetting } from "../store/derby";
import { useId } from "react";

function CheckboxSetting({
  setting,
  displayName,
}: {
  setting: SettingKey;
  displayName: string;
}) {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);
  const dispatch = useDispatch();

  const checkboxInputId = useId();

  return (
    <div className="flex items-center">
      <label className="ps-4" htmlFor={checkboxInputId}>
        {displayName}
      </label>
      <input
        className="m-1 rounded p-1.5"
        id={checkboxInputId}
        type="checkbox"
        onClick={() =>
          dispatch(setSetting({ [setting]: !derby.settings[setting] }))
        }
        checked={derby.settings[setting]}
      />
    </div>
  );
}

export default CheckboxSetting;
