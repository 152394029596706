import { useSelector } from "react-redux";
import { Derby } from "../types";
import RaceControl from "../components/RaceControl";
import ScoresControl from "../components/ScoresControl";

function Scorekeeper() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);

  return (
    <div>
      <h1 className="text-center font-racing text-3xl">{derby.name}</h1>
      <RaceControl />
      {derby.currentRace !== undefined && <ScoresControl />}
    </div>
  );
}

export default Scorekeeper;
