import { useSelector } from "react-redux";
import { Derby } from "../types";
import RawResultsTable from "../components/RawResultsTable";
import RankResultTable from "../components/RankResultsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { calculatePoints, exportData } from "../utils";

function Results() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);

  const handleExportResults = () => {
    const header = [
      "race number",
      Array(derby.numberLanes)
        .fill(0)
        .map((_, laneIndex) =>
          ["car", "name", "place"].map(
            (e) => "lane " + (laneIndex + 1) + " " + e,
          ),
        )
        .join(","),
    ].join(",");
    const data = derby.races
      .map((race, raceIndex) =>
        [
          raceIndex + 1,
          race.racers
            .map((racerIndex, laneIndex) =>
              [
                derby.racers[racerIndex].number,
                derby.racers[racerIndex].name,
                race.results[laneIndex],
              ].join(","),
            )
            .join(","),
        ].join(","),
      )
      .join("\n");
    exportData("open_derby_results.csv", new Blob([header, "\n", data]));
  };
  const handleExportScores = () => {
    const ranking = calculatePoints(derby);
    const data = ranking
      .map((rank) =>
        [
          rank.place,
          derby.racers[rank.racerIndex].number,
          derby.racers[rank.racerIndex].name,
          rank.points,
        ].join(","),
      )
      .join("\n");
    exportData(
      "open_derby_scores.csv",
      new Blob(["place,car,name,points\n", data]),
    );
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-center font-racing text-3xl">{derby.name}</h1>
      <div className="my-4">
        <h2 className="my-2 text-center font-racing text-xl">Results</h2>
        <RawResultsTable />
        <div className="flex justify-center">
          <button
            className="mx-2 my-2 rounded-full bg-yellow-600 px-5 py-2 text-white shadow-md hover:bg-yellow-400"
            onClick={handleExportResults}
          >
            <FontAwesomeIcon icon={faFileExport} /> Export Results
          </button>
        </div>
      </div>
      <div className="my-4">
        <h2 className="my-2 text-center font-racing text-xl">Scoring</h2>
        <RankResultTable />
        <div className="flex justify-center">
          <button
            className="mx-2 my-2 rounded-full bg-yellow-600 px-5 py-2 text-white shadow-md hover:bg-yellow-400"
            onClick={handleExportScores}
          >
            <FontAwesomeIcon icon={faFileExport} /> Export Scores
          </button>
        </div>
      </div>
    </div>
  );
}

export default Results;
