import { useSelector } from "react-redux";
import { Derby } from "../types";
import ScoreControl from "./ScoreControl";

function ScoresControl() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);

  const controls = Array(derby.numberLanes)
    .fill(0)
    .map((_, i) => <ScoreControl laneIndex={i} />);

  if (derby.settings.reverseScorekeeper) {
    controls.reverse();
  }

  return <div className="flex justify-center">{controls}</div>;
}

export default ScoresControl;
