import { useDispatch, useSelector } from "react-redux";
import { setRaceResult } from "../store/derby";
import { Derby } from "../types";
import { ChangeEvent, useCallback, useId } from "react";

function ScoreControl({ laneIndex }: { laneIndex: number }) {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);
  const dispatch = useDispatch();
  const scoreInputId = useId();

  const placeInput = useCallback(
    (inputElement: HTMLInputElement) => {
      const childIndex = derby.settings.reverseScorekeeper
        ? laneIndex - (derby.numberLanes - 1)
        : laneIndex;
      if (inputElement && !childIndex) {
        inputElement.focus();
      }
    },
    // eslint-disable-next-line
    [derby.currentRace, laneIndex],
  );

  const handleSetPlace = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setRaceResult({ laneIndex, place: Number(e.target.value) }));
  };

  return (
    <div className="mx-2 flex flex-col text-center">
      <label htmlFor={scoreInputId}>Lane {laneIndex + 1}</label>
      <input
        className="w-20 rounded-full border py-1 text-center"
        type="text"
        id={scoreInputId}
        value={derby.races[derby.currentRace!].results[laneIndex] || ""}
        onChange={handleSetPlace}
        tabIndex={1}
        ref={placeInput}
      />
    </div>
  );
}

export default ScoreControl;
