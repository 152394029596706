import { createSlice } from "@reduxjs/toolkit";
import { Derby, Racer, DerbyGenerator, Settings } from "../types";
import { equalShift, laneRotation, phaseShift } from "../utils";
import { MAX_LANES, MIN_LANES } from "../constants";

const initialState: Derby = {
  name: "New Derby",
  generatorType: "phaseShift",
  numberLanes: 2,
  racers: [],
  races: [],
  currentRace: undefined,
  settings: {
    reverseDisplay: false,
    reverseScorekeeper: false,
  },
};

function runGenerator(state: Derby, custom: number[][] | null = null) {
  state.currentRace = undefined;
  if (state.racers.length < state.numberLanes) {
    state.races = [];
    return;
  }

  let derby: number[][] | null = custom;
  if (state.generatorType === "equalShift") {
    derby = equalShift(state.numberLanes, state.racers.length);
  } else if (state.generatorType === "laneRotation") {
    derby = laneRotation(state.numberLanes, state.racers.length);
  } else if (state.generatorType === "phaseShift") {
    derby = phaseShift(state.numberLanes, state.racers.length);
  }

  if (derby) {
    state.races = derby.map((race, index) => ({
      number: index + 1,
      racers: race,
      results: Array(race.length).fill(""),
    }));
  }
}

export const derbySlice = createSlice({
  name: "derby",
  initialState,
  reducers: {
    setName: (state, action: { type: string; payload: string }) => {
      state.name = action.payload;
    },
    setGenerator: (
      state,
      action: { type: string; payload: DerbyGenerator },
    ) => {
      state.generatorType = action.payload;
      runGenerator(state);
    },
    setNumberLanes: (state, action: { type: string; payload: number }) => {
      if (action.payload > MAX_LANES || action.payload < MIN_LANES) {
        return;
      }
      state.numberLanes = action.payload;
      runGenerator(state);
    },
    addRacer: (
      state,
      action: {
        type: string;
        payload?: Partial<Racer>;
      },
    ) => {
      let newRacer = { name: "", number: "" };
      if (action && action.payload) {
        if (action.payload.name) {
          newRacer.name = action.payload.name;
        }
        if (action.payload.number) {
          newRacer.number = action.payload.number;
        }
      }
      state.racers = [...state.racers, newRacer];
      runGenerator(state);
    },
    removeRacer: (state, action: { type: string; payload: number }) => {
      state.racers = state.racers.filter(
        (_, index) => index !== action.payload,
      );
      runGenerator(state);
    },
    editRacer: (
      state,
      action: {
        type: string;
        payload: { index: number; update: Partial<Racer> };
      },
    ) => {
      const index = action.payload.index;
      state.racers[index] = {
        ...state.racers[index],
        ...action.payload.update,
      };
    },
    setRaces: (state, action: { type: string; payload: number[][] }) => {
      runGenerator(state, action.payload);
    },
    setCurrentRace: (
      state,
      action: { type: string; payload: number | undefined },
    ) => {
      state.currentRace = action.payload;
    },
    setRaceResult: (
      state,
      action: {
        type: string;
        payload: { laneIndex: number; place: number | undefined };
      },
    ) => {
      if (state.currentRace === undefined) {
        return;
      }

      if (
        action.payload.place !== undefined &&
        (isNaN(action.payload.place) ||
          action.payload.place <= 0 ||
          action.payload.place > state.numberLanes)
      ) {
        action.payload.place = undefined;
      }

      state.races[state.currentRace].results[action.payload.laneIndex] =
        action.payload.place;
    },
    setSetting: (
      state,
      action: {
        type: string;
        payload: Partial<Settings>;
      },
    ) => {
      state.settings = { ...state.settings, ...action.payload };
    },
  },
});

export const {
  setName,
  setGenerator,
  setNumberLanes,
  addRacer,
  removeRacer,
  editRacer,
  setRaces,
  setCurrentRace,
  setRaceResult,
  setSetting,
} = derbySlice.actions;

export default derbySlice.reducer;
