function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

function laneRotation(numLanes: number, numCars: number): number[][] {
  let final: number[][] = [];

  for (let j = 0; j < numCars; j++) {
    let cur: number[] = [];
    for (let i = 0; i < numLanes; i++) {
      cur.push((i + j) % numCars);
    }
    final.push(cur);
  }

  return final;
}

function equalShift(numLanes: number, numCars: number): number[][] {
  let final: number[][] = [];

  const offset = Math.floor(numCars / numLanes);
  for (let j = 0; j < numCars; j++) {
    let cur: number[] = [];
    for (let i = 0; i < numLanes; i++) {
      const curCar = mod(j - offset * i, numCars);
      cur.push(curCar);
    }
    final.push(cur);
  }

  return final;
}

function phaseShift(numLanes: number, numCars: number): number[][] {
  let final: number[][] = [];

  const offset = Math.floor(
    (numCars - ((numLanes - 1) * (numLanes - 2)) / 2) / numLanes,
  );
  if (offset < 1) {
    return equalShift(numLanes, numCars);
  }
  for (let j = 0; j < numCars; j++) {
    let cur: number[] = [];
    let extraShift = 0;
    for (let i = 0; i < numLanes; i++) {
      const curCar = mod(j - (offset * i + extraShift), numCars);
      extraShift += i;
      cur.push(curCar);
    }
    final.push(cur);
  }

  return final;
}

export { laneRotation, equalShift, phaseShift };
