import { useDispatch, useSelector } from "react-redux";
import { Derby } from "../types";
import { setName } from "../store/derby";
import { ChangeEvent, useId } from "react";
import NumLanesControl from "../components/NumLanesControl";
import GenTypeControl from "../components/GenTypeControl";
import CheckboxSetting from "../components/CheckboxSetting";

function Settings() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);
  const dispatch = useDispatch();

  const nameInputId = useId();

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setName(e.target.value));
  };

  return (
    <div className="container mx-auto p-1">
      <div className="flex flex-col">
        <div>
          <label className="block ps-4" htmlFor={nameInputId}>
            Derby Name
          </label>
          <input
            className="rounded-full border px-4 py-1"
            type="text"
            id={nameInputId}
            value={derby.name}
            onChange={handleNameChange}
          />
        </div>
        <NumLanesControl />
        <GenTypeControl />
        <CheckboxSetting
          setting="reverseDisplay"
          displayName="Reverse Lane Order on Display"
        />
        <CheckboxSetting
          setting="reverseScorekeeper"
          displayName="Reverse Lane Order on Scorekeeper"
        />
      </div>
    </div>
  );
}

export default Settings;
