import { useSelector } from "react-redux";
import { Derby } from "../types";
import { calculatePoints } from "../utils";

function RankResultTable() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);

  const ranking = calculatePoints(derby);

  return (
    <div className="">
      <table className="mx-auto bg-white shadow-md">
        <thead className="bg-slate-800 text-white">
          <tr>
            <th className="px-3 py-1">Place</th>
            <th className="px-3 py-1">Racer</th>
            <th className="px-3 py-1">Points</th>
          </tr>
        </thead>
        <tbody>
          {ranking.map((v) => (
            <tr className="even:bg-slate-100">
              <td className="border-x px-3 py-1">{v.place}</td>
              <td className="border-x px-3 py-1">
                [{derby.racers[v.racerIndex].number}]{" "}
                {derby.racers[v.racerIndex].name}
              </td>
              <td className="border-x px-3 py-1">{v.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RankResultTable;
