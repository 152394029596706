import { configureStore } from "@reduxjs/toolkit";
import derbyReducer from "./derby";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/es/stateReconciler/hardSet";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

const persistConfig = {
  key: "derby",
  storage,
  stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, derbyReducer);

export const store = configureStore({
  reducer: {
    derby: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      createStateSyncMiddleware({
        blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
      }) as any,
    ),
});

initMessageListener(store);

export const persistor = persistStore(store);
