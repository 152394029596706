// import libraries
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// import utilities
import ShowMenu from "./components/ShowMenu";

// import pages
import NotFound from "./pages/NotFound";
import Scorekeeper from "./pages/Scorekeeper";
import Display from "./pages/Display";
import Settings from "./pages/Settings";
import Results from "./pages/Results";
import Racers from "./pages/Racers";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ShowMenu>
        <Settings />
      </ShowMenu>
    ),
  },
  {
    path: "/racers",
    element: (
      <ShowMenu>
        <Racers />
      </ShowMenu>
    ),
  },
  {
    path: "/display",
    element: <Display />,
  },
  {
    path: "/results",
    element: (
      <ShowMenu>
        <Results />
      </ShowMenu>
    ),
  },
  {
    path: "/scorekeeper",
    element: (
      <ShowMenu>
        <Scorekeeper />
      </ShowMenu>
    ),
  },
  {
    path: "*",
    element: (
      <ShowMenu>
        <NotFound />
      </ShowMenu>
    ),
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
