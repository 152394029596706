import { Derby } from "../types";

function calculatePoints(
  derby: Derby,
): { racerIndex: number; points: number; place: number }[] {
  let results = derby.racers.map((r, i) => ({
    racerIndex: i,
    points: 0,
    place: -1,
  }));

  for (const r of derby.races) {
    for (let i = 0; i < derby.numberLanes; i++) {
      const cur = r.results[i];
      if (cur) {
        results[r.racers[i]].points += derby.numberLanes - cur + 1;
      }
    }
  }
  results.sort((a, b) => b.points - a.points);

  let lastScore = -1;
  let lastPlace = 0;
  for (const i in results) {
    if (results[i].points === lastScore) {
      results[i].place = lastPlace;
      continue;
    }
    lastPlace = Number(i) + 1;
    results[i].place = lastPlace;
    lastScore = results[i].points;
  }

  return results;
}

export { calculatePoints };
