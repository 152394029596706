import { useDispatch, useSelector } from "react-redux";
import { Derby } from "../types";
import { setCurrentRace } from "../store/derby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";

function RaceControl() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);
  const dispatch = useDispatch();

  const handleStart = () => {
    dispatch(setCurrentRace(0));
  };
  const handlePrevRace = () => {
    dispatch(setCurrentRace(derby.currentRace! - 1));
  };
  const handleNextRace = () => {
    dispatch(setCurrentRace(derby.currentRace! + 1));
  };

  if (derby.currentRace === undefined) {
    return (
      <div className="text-center">
        <div className="flex justify-center">
          <button
            className="my-2 rounded-full bg-green-600 px-5 py-2 text-white shadow-md hover:bg-green-400 disabled:cursor-not-allowed disabled:bg-slate-100 disabled:shadow-none"
            onClick={handleStart}
            disabled={!derby.races.length}
          >
            <FontAwesomeIcon icon={faPlayCircle} /> Start Derby{" "}
          </button>
        </div>
        {!derby.races.length && (
          <span className="text-center">
            Use Settings and Racers to finish setting up this derby.
          </span>
        )}
      </div>
    );
  }

  const disabledRaceAction = (q: number) => q < 0 || q >= derby.races.length;
  return (
    <div className="my-2 flex items-center justify-center">
      <button
        className="w-32 rounded-full bg-slate-800 py-2 text-white shadow-md hover:bg-slate-700 disabled:cursor-not-allowed disabled:bg-slate-100 disabled:shadow-none"
        disabled={disabledRaceAction(derby.currentRace - 1)}
        onClick={handlePrevRace}
        tabIndex={3}
      >
        <FontAwesomeIcon icon={faArrowLeft} /> Previous
      </button>
      <span className="min-w-24 text-center">
        Race #{derby.currentRace + 1}
      </span>
      <button
        className="w-32 rounded-full bg-slate-800 py-2 text-white shadow-md hover:bg-slate-700 disabled:cursor-not-allowed disabled:bg-slate-100 disabled:shadow-none"
        disabled={disabledRaceAction(derby.currentRace + 1)}
        onClick={handleNextRace}
        tabIndex={2}
      >
        Next <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  );
}

export default RaceControl;
