function DisplayRacer({
  name,
  number,
  lane,
  place,
}: {
  name: string;
  number: string;
  lane: number;
  place: number | undefined;
}) {
  return (
    <div className="m-4 flex flex-1 flex-col rounded-xl bg-white shadow-md">
      <div className="">
        <span className="block w-full rounded-t-xl bg-slate-800 py-2 text-xl text-white">
          Lane {lane}
        </span>
        <div className="flex justify-between px-4 py-3">
          <div className="flex flex-col text-left">
            <span className="text-3xl">{name}</span>
            <span className="text-2xl">Car: {number}</span>
          </div>
          <div className="text-right text-6xl">
            {place && <span>{place}</span>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplayRacer;
