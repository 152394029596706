import { useSelector } from "react-redux";
import { Derby, Race } from "../types";

function RawResultsTable() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);

  return (
    <div>
      <table className="mx-auto bg-white shadow-md">
        <thead className="bg-slate-800 text-white">
          <tr>
            {Array(derby.numberLanes)
              .fill(0)
              .map((_, i) => (
                <>
                  <th className="px-3 py-1">Racer</th>
                  <th className="px-3 py-1">Place</th>
                </>
              ))}
          </tr>
        </thead>
        <tbody>
          {derby.races.map((v: Race) => (
            <tr className="even:bg-slate-100">
              {v.racers.map((r, i) => (
                <>
                  <td className="border-x px-3 py-1">
                    [{derby.racers[r].number}] {derby.racers[r].name}
                  </td>
                  <td className="border-x px-3 py-1">{v.results[i]}</td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RawResultsTable;
