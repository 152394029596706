import { useDispatch, useSelector } from "react-redux";
import { Derby } from "../types";
import { editRacer, removeRacer } from "../store/derby";
import { ChangeEvent, useId } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function EditableRacer({ index }: { index: number }) {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);
  const dispatch = useDispatch();

  const nameInputId = useId();
  const numberInputId = useId();

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(editRacer({ index, update: { name: e.target.value } }));
  };
  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(editRacer({ index, update: { number: e.target.value } }));
  };
  const handleRemoveRacer = () => {
    dispatch(removeRacer(index));
  };

  return (
    <div className="my-2 flex flex-col justify-center sm:flex-row">
      <div className="mx-1">
        <label className="ms-4 block" htmlFor={nameInputId}>
          Name
        </label>
        <input
          className="block w-full rounded-full px-4 py-1"
          type="text"
          id={nameInputId}
          onChange={handleNameChange}
          value={derby.racers[index].name}
        />
      </div>
      <div className="mx-1">
        <label className="ms-4 block" htmlFor={numberInputId}>
          Number
        </label>
        <input
          className="block w-full rounded-full px-4 py-1"
          type="text"
          id={numberInputId}
          onChange={handleNumberChange}
          value={derby.racers[index].number}
        />
      </div>
      <div className="m-1 flex items-end justify-center">
        <button
          className="rounded-full bg-red-700 px-4 py-2 text-white shadow-md hover:bg-red-600"
          onClick={handleRemoveRacer}
        >
          <FontAwesomeIcon icon={faTrash} /> Delete
        </button>
      </div>
    </div>
  );
}

export default EditableRacer;
