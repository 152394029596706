import { useDispatch, useSelector } from "react-redux";
import { Derby } from "../types";
import { setGenerator } from "../store/derby";
import { useId } from "react";

function GenTypeControl() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);
  const dispatch = useDispatch();

  const genertatorTypeInputId = useId();

  const handleGeneratorChange = (e: any) => {
    dispatch(setGenerator(e.target.value));
  };

  return (
    <div>
      <label className="block ps-4" htmlFor={genertatorTypeInputId}>
        Generator Type
      </label>
      <select
        className="w-48 appearance-none rounded-full border bg-white px-4 py-1"
        id={genertatorTypeInputId}
        onChange={handleGeneratorChange}
        value={derby.generatorType}
      >
        <option value="phaseShift">Phase Shift</option>
        <option value="equalShift">Equal Shift</option>
        <option value="laneRotation">Lane Rotation</option>
      </select>
    </div>
  );
}

export default GenTypeControl;
