import { useDispatch, useSelector } from "react-redux";
import { Derby } from "../types";
import { setNumberLanes } from "../store/derby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ChangeEvent, useEffect, useId, useState } from "react";
import { MIN_LANES } from "../constants";

function NumLanesControl() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);
  const dispatch = useDispatch();

  const lanesInputId = useId();

  const [inputValue, setInputValue] = useState(MIN_LANES);
  useEffect(() => {
    setInputValue(derby.numberLanes);
  }, [derby.numberLanes]);
  const handleNumLaneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(Number(e.target.value));
  };
  const handleNumLaneBlur = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setNumberLanes(inputValue));
    setInputValue(derby.numberLanes);
  };

  const handleNumLanePlus = () => {
    dispatch(setNumberLanes(derby.numberLanes + 1));
  };
  const handleNumLaneMinus = () => {
    dispatch(setNumberLanes(derby.numberLanes - 1));
  };

  return (
    <div>
      <label className="block ps-4" htmlFor={lanesInputId}>
        Number of Lanes
      </label>
      <div className="">
        <button
          className="w-12 rounded-s-full border border-e-0 border-gray-500 bg-slate-800 py-1 pe-4 text-right text-white hover:bg-slate-700"
          onClick={handleNumLaneMinus}
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <input
          className="w-12 border-0 border-y py-1 text-center"
          type="number"
          id={lanesInputId}
          value={inputValue}
          onBlur={handleNumLaneBlur}
          onChange={handleNumLaneChange}
        />
        <button
          className="w-12 rounded-e-full border border-s-0 border-gray-500 bg-slate-800 py-1 ps-4 text-left text-white hover:bg-slate-700"
          onClick={handleNumLanePlus}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </div>
  );
}

export default NumLanesControl;
