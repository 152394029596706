import { useSelector } from "react-redux";
import { Derby, Race } from "../types";
import DisplayRacer from "./DisplayRacer";

function DisplayRacers({ raceIndex }: { raceIndex: number }) {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);
  const race: Race = derby.races[raceIndex];

  const racers = race.racers.map((racer, index) => (
    <DisplayRacer
      name={derby.racers[racer].name}
      number={derby.racers[racer].number}
      lane={index + 1}
      place={derby.races[raceIndex].results[index]}
    />
  ));

  if (derby.settings.reverseDisplay) {
    racers.reverse();
  }

  return <div className="flex">{racers}</div>;
}

export default DisplayRacers;
