import { useSelector } from "react-redux";
import { Derby } from "../types";
import WelcomeMessage from "../components/WelcomeMessage";
import DisplayRacers from "../components/DisplayRacers";

function Display() {
  const derby: Derby = useSelector((state: { derby: Derby }) => state.derby);

  console.log(derby);

  if (derby.currentRace === undefined) {
    return (
      <div className="bg-slate-100">
        <WelcomeMessage />
      </div>
    );
  }

  return (
    <div className="flex min-h-dvh flex-col text-center font-racing">
      <h1 className="flex-none text-5xl">{derby.name}</h1>
      <div className="flex flex-1 flex-col">
        <div className="my-8">
          <h2 className="text-2xl">Racing Now</h2>
          <DisplayRacers raceIndex={derby.currentRace} />
        </div>
        {derby.currentRace + 1 < derby.races.length && (
          <div className="my-8">
            <h2 className="text-2xl">Up Next</h2>
            <DisplayRacers raceIndex={derby.currentRace + 1} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Display;
