import { ReactNode } from "react";
import { Link } from "react-router-dom";

function ShowMenu({ children }: { children: ReactNode }) {
  return (
    <div>
      <div className="bg-slate-900 text-white">
        <div className="container mx-auto flex h-12 items-center">
          <Link className="rounded-full px-2 py-1 hover:bg-slate-700" to="/">
            Settings
          </Link>
          <Link
            className="rounded-full px-2 py-1 hover:bg-slate-700"
            to="/racers"
          >
            Racers
          </Link>
          <Link
            className="rounded-full px-2 py-1 hover:bg-slate-700"
            to="/scorekeeper"
          >
            Scorekeeper
          </Link>
          <Link
            className="rounded-full px-2 py-1 hover:bg-slate-700"
            to="/results"
          >
            Results
          </Link>
          <Link
            className="rounded-full px-2 py-1 hover:bg-slate-700"
            to="/display"
            target="_blank"
          >
            Display
          </Link>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
}

export default ShowMenu;
